<template>
	<div class="Customer_Information_form custom-modal-wrap">

		<el-dialog :title="modal_title" :visible.sync="shows" @closed="onclosed" :close-on-click-modal="false"
			:close-on-press-escape="false">
			<div class="form_box">

				<div class="form_flex">
					<div class="lefts">
						角色名称
					</div>
					<div class="rights">
						<el-input v-model="form.name" placeholder="请输入"></el-input>
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts2">
						角色描述
					</div>
					<div class="rights">
						<el-input v-model="form.description" placeholder="请输入" :disabled="is_default" ></el-input>
					</div>
				</div>
				<div class="form_flex">
					<div class="lefts" style="visibility: hidden;">
						菜单列表
					</div>
					<div class="rights">
						<div class="values">
							<!-- <el-checkbox-group v-model="checkList">
								<el-checkbox v-for="(item,index) in menu_list" :label="item.id" v-if="item.id!=1">
									{{item.title}}
								</el-checkbox>
							</el-checkbox-group> -->
							<!-- <div class="allCheck">
								<el-checkbox label="全选"></el-checkbox>
							</div> -->
							<el-tree :data="cdlb_list" show-checkbox node-key="type" :props="defaultProps"
								@check-change="handleCheckChange" @check="current_change" :highlight-current="true" :check-on-click-node="false" :default-checked-keys="cdlb_list_ac">
							</el-tree>
						</div>
					</div>
				</div>


				<div class="btn_flex">
					<div class="btns2" @click="handle_ends" v-if="!form.id">确定</div>
					<div class="btns2" @click="handle_ends" v-if="form.id">确定</div>
					<div class="btns1" @click="handle_close">取消</div>
				</div>
			</div>
		</el-dialog>

	</div>
</template>
<script>
export default {
	name: "Customer_Information_form",
	props: {

	},
	data() {
		return {
			modal_title: '创建账号',
			shows: false,
			form: {
				id: 0,
				name: '',
				description:'',//描述
				roles: '', //角色权限 多个用，分割
			},
			is_default:'',
			menu_list: [],
			checkList: [],

			//
			//
			juese_list: [{
				title: '类别1',
				value: '1'
			},
			{
				title: '类别2',
				value: '2'
			},
			],
			bumen_select: '',
			cdlb_list_ac:[],
			cdlb_list: [
				{
					title: '员工管理',
					type: 1,
					ac_show:false,
					image: require('@/assets/img/index/tab1.png'),
					list: [{
						title: '员工信息',
						path: '/Employee_Information',
						type: '1_1',
						ac_show:false,
					},
					{
						title: '通讯管理',
						path: '/Newsletter_management',
						type: '1_2',
						ac_show:false,
					},
					{
						title: '合同管理',
						path: '/Contract_management',
						type: '1_3',
						ac_show:false,
					},
					{
						title: '员工调动',
						path: '/Employee_transfers',
						type: '1_4',
						ac_show:false,
					},
					{
						title: '组织架构',
						path: '/Organizational_structure',
						type: '1_5',
						ac_show:false,
					},
					{
						title: '岗位列表',
						path: '/post_management',
						type: '1_6',
						ac_show:false,
					}

					]
				},
				{
					title: '薪酬管理',
					type: 2,
					ac_show:false,
					image: require('@/assets/img/index/tab2.png'),
					list: [
						{
							title: '社保管理',
							path: '/Social_security_management',
							type: '2_1',
							ac_show:false,
						},
						{
							title: '薪资设置',
							path: '/Salary_Management',
							type: '2_2',
							ac_show:false,
						},

						{
							title: '员工标准工资设置',
							path: '/Salary_setting',
							type: '2_3',
							ac_show:false,
						},
						{
							title: '工资下发',
							path: '/Salary_distribution',
							type: '2_4',
							ac_show:false,
						},

						{
							title: '薪资台账',
							path: '/Employee_salary',
							type: '2_5',
							ac_show:false,
						},
						// {
						// 	title: '结算月数据',
						// 	path: '/Billing_month_data',
						// }
					]
				},
				{
					title: '考勤管理',
					type: 3,
					ac_show:false,
					image: require('@/assets/img/index/tab3.png'),
					list: [{
						title: '考勤设置',
						path: '/Attendance_settings',
						type: '3_1',
						ac_show:false,
						list: [{
							title: '考勤规则',
							path: '/Attendance_settings/Attendance_rules',
							type: '3_1_1',
							ac_show:false,
						},
						{
							title: '免考勤人员设置',
							path: '/Attendance_settings/Attendance_no',
							type: '3_1_2',
							ac_show:false,
						},
						{
							title: '下井设置',
							path: '/Attendance_settings/Downhole_settings',
							type: '3_1_3',
							ac_show:false,
						},
						{
							title: '节假日设置',
							path: '/Attendance_settings/Holiday_settings',
							type: '3_1_4',
							ac_show:false,
						},
						]
					},
					{
						title: '考勤数据',
						path: '/Attendance_data',
						type: '3_2',
						ac_show:false,
						list: [{
							title: '每日考勤',
							path: '/Attendance_data/Daily_statistics',
							type: '3_2_1',
							ac_show:false,
						},
						{
							title: '月度考勤',
							path: '/Attendance_data/Monthly_rollups',
							type: '3_2_2',
							ac_show:false,
						}
						]
					}
						// {
						// 	title: '每日统计',
						// 	path: '/Daily_statistics',
						// },
						// {
						// 	title: '月度汇总',
						// 	path: '/Monthly_rollups',
						// },
						// {
						// 	title: '历史查询',
						// 	path: '/Historical_queries',
						// }
					]
				},
				{
					title: '员工福利',
					type: 4,
					ac_show:false,
					image: require('@/assets/img/index/tab4.png'),
					list: [
						{
							title: '礼品管理',
							path: '/Gift_management',
							type: '4_1',
							ac_show:false,
						},
						{
							title: '礼品下发',
							path: '/Gift_distributed',
							type: '4_2',
							ac_show:false,
						}
					]
				},
				{
					title: '劳保用品',
					type: 5,
					ac_show:false,
					image: require('@/assets/img/index/tab5.png'),
					list: [
						{
							title: '劳保用品管理',
							path: '/Labor_Management',
							type: '5_1',
							ac_show:false,
						},
						{
							title: '劳保用品下发',
							path: '/Labor_distributed',
							type: '5_2',
							ac_show:false,
						},
						{
							title: '领取劳保用品',
							path: '/Labor_Receive',
							type: '5_3',
							ac_show:false,
						}
					]
				},
				{
					title: '统计报表',
					type: 6,
					ac_show:false,
					image: require('@/assets/img/index/tab6.png'),
					list: [{
						title: '实时报表',
						path: '/Real_time_reports',
						type: '6_1',
						ac_show:false,
					},
					{
						title: '月报预览',
						path: '/monthly_report',
						type: '6_2',
						ac_show:false,
					},
					{
						title: '历史月报',
						path: '/Historical_Monthly',
						type: '6_3',
						ac_show:false,
					}
					]
				},
				{
					title: '账号管理',
					type: 7,
					ac_show:false,
					image: require('@/assets/img/index/tab7.png'),
					list: [{
						title: '账号管理',
						path: '/Account_management',
						type: '7_1',
						ac_show:false,
					},
					{
						title: '角色管理',
						path: '/Role_management',
						type: '7_2',
						ac_show:false,
					},
					{
						title: '系统设置',
						path: '/system_setting',
						type: '7_3',
						ac_show:false,
					},
					{
						title: '操作日志',
						path: '/History_recording',
						type: '7_4',
						ac_show:false,
					}
					]
				},
			],
			defaultProps: {
				children: 'list',
				label: 'title'
			}
		};
	},
	computed: {},

	watch: {

		checkList(value) {
			console.log('角色列表 value', value)
			this.form.roles = value.join(',')
		}
	},
	created() {
		this.query_menu()
	},
	methods: {
		handleCheckChange(data, checked, indeterminate) {
			// data.ac_show=checked
			// if(data.list){
			// 	data.list.forEach((e)=>{
			// 		e.ac_show=checked
			// 		if(e.list){
			// 			e.list.forEach((v)=>{
			// 				v.ac_show=checked
			// 			})
			// 		}
			// 	})
			// }
			// console.log('handleCheckChange',data);
			// console.log('handleCheckChange',checked);
			// console.log('handleCheckChange',indeterminate);
		},
		current_change(data,nodes){
			// console.log('current_change',data)
			// if(data.ac_show){

			// 	data.ac_show=false
			// }else{

			// 	data.ac_show=true
			// }
			// console.log('data',data)
			console.log('current_change',nodes)
			this.cdlb_list_ac=nodes.checkedKeys
			// this.cdlb_list.forEach((e)=>{
			// 	let ac_show=false//最外层
			// 	e.list.forEach((e1)=>{
			// 		if(e1.list){
			// 			if(e1.ac_show){
			// 				let ac_show1=false//第二层
			// 				e1.list.forEach((e2)=>{
			// 					if(e2.ac_show){
			// 						ac_show1=true
			// 						ac_show=true
			// 					}
			// 					e1.ac_show=ac_show1
			// 					// e2.ac_show=ac_show2
			// 				})
			// 			}
						
			// 		}else{
			// 			if(e1.ac_show){
			// 				ac_show=true
			// 			}
			// 		}
					
			// 	})
			// 	e.ac_show=ac_show
			// })
			// console.log('current_change',this.cdlb_list[2])

		 
		},
		onclosed() {
			this.checkList = []
			this.form = {
				id: 0,
				name: '',
				roles: '', //角色权限 多个用，分割
			}
		},
		query_menu() {
			this.$api("getMenuList", {

			}, "get").then((res) => {
				if (res.code == 200) {
					this.menu_list = res.data;
				} else {
					alertErr(res.msg)
				}
			});
		},

		init(row) {
			this.shows = true
			if (row) {
				this.modal_title = '编辑角色'
				this.query_detail(row)
			} else {
				this.modal_title = '创建角色'
			}
		},

		query_detail(row) {
			this.$api("getAdminRole", {
				id: row.id
			}, "get").then((res) => {
				if (res.code == 200) {
					let data = res.data;
					this.form = {
						id: data.id || 0,
						name: data.name || '',
						roles: data.roles || '',
						description: data.description || '',
						
					}
					this.is_default=res.data.is_default
					this.cdlb_list_ac=[]
					if (data.roles) {
						this.cdlb_list_ac=JSON.parse(data.roles)
					}
				}
			});
		},


		// 新增打开
		handle_add_open(row) {
			this.title = ''
			this.is_default=''
			this.shows = true
		},
		//修改打开
		handle_modify_open(row) {
			this.title = ''
			let rows = JSON.parse(JSON.stringify(row))
			this.form = rows
			console.log('row', row)

			this.shows = true
		},
		//关闭    
		handle_close() {
			this.shows = false

		},
		//确认添加修改
		handle_ends() {

			console.log('cdlb_list_ac',this.cdlb_list_ac)
			if (!this.form.name) {
				alertErr('请输入角色名称')
				return
			}
			 
			if (this.cdlb_list_ac.length==0) {
				alertErr('请选择菜单')
				return
			}
			this.form.roles=JSON.stringify(this.cdlb_list_ac)


			this.loading = true
			this.$api("editAdminRole", {
				...this.form
			}, "post").then((res) => {
				alert(res)
				this.loading = false
				if (res.code == 200) {
					this.shows = false
					this.$emit("confirm");
				}
			});
		},
	},

};
</script>

<style scoped lang="less">
.custom-modal-wrap {
	/deep/ .el-dialog .el-dialog__header {
		background: #fff !important;
	}

	.lefts {
		text-align: right;
		margin-right: 15px;
	}
	.lefts2{
		text-align: right;
		margin-right: 15px;
	}
}

/deep/ .el-dialog__body {
	padding: 30px 50px 0 50px;
}

.btn_flex {
	padding: 25px 0;
	display: flex;
	justify-content: center !important;
}


.Customer_Information_form {
	/deep/.el-dialog {
		width: 680px;
	}

	/deep/.el-dialog__header {
		background: #F2F5FA !important;
	}

	.form_box {
		.form_flex {
			display: flex;
			align-items: center;
			margin-bottom: 16px;

			.lefts {
				min-height: 40px;
				line-height: 40px;
				width: 120px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 14px;
				color: #333333;

				&::before {
					content: '*';
					display: inline-block;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 16px;
					color: #FC5F03;
				}
			}
			.lefts2{
				min-height: 40px;
				line-height: 40px;
				width: 120px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 14px;
				color: #333333;

			 
			}

			.rights {
				// width: 446px;
				flex: 1;
				min-height: 40px;
				height: auto;
				// background: #F2F5FA;
				border-radius: 4px 4px 4px 4px;

				input {
					padding: 0 12px;
					width: 100%;
					height: 40px;
				}

				/deep/.el-input {
					width: 100%;

					.el-input__inner {
						// background: #F2F5FA;
						// border: 0;
					}

					.el-input__inner {
						// background: #F2F5FA;
						// border: 0;
					}
				}

				.el-select {
					width: 100%;
				}
			}
		}

		.btn_flex {
			display: flex;
			align-items: center;
			justify-content: end;

			.btns1 {
				cursor: pointer;
				text-align: center;
				margin: 0 11px;
				width: 91px;
				height: 38px;
				line-height: 38px;
				background: #FFFFFF;
				border-radius: 4px 4px 4px 4px;
				border: 1px solid #B8B8B8;

				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 16px;
				color: #6A6A6A;
			}

			.btns2 {
				cursor: pointer;
				text-align: center;
				margin: 0 11px;

				width: 91px;
				height: 38px;
				line-height: 38px;
				background: #2373C8;
				border-radius: 4px 4px 4px 4px;

				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 16px;
				color: #FFFFFF;
			}
		}
	}

}
</style>