<template>
	<div class="page_box">
		<juese_add_modal data-title='账号表单' ref="juese_add_modal" @confirm="do_add_confirm" />


		<div class="bom_box">
			<div class="account-titlebox">
				<div class="title">{{page_title}}</div>
				<div class="two-toggle">
					<div class="tabs">
						<span v-for="(item,index) in guanli" :key="index" :class="{active:activeIndex == item.status}"
							@click="toStatus(item)">{{item.title}}</span>
					</div>
				</div>
				<div class="created-account">
					<div class="created-span" @click="do_add()">创建角色</div>
					<div class="end-flex">
						<div class="item-1">
							<span class="key">角色</span>
							<!-- 			<el-select v-model="form_select.juese" placeholder="请输入角色">
								<el-option v-for="item in jueseList" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select> -->
							<div class="form-value">
								<el-input clearable v-model="forms.keyword" placeholder="请输入"></el-input>
							</div>

						</div>
						<div class="seacher-box" @click="do_search()">搜索</div>
					</div>
				</div>
			</div>

			<div class="bom_box_list">
				<el-table :data="data_list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<!-- 暂时无需 -->
					<el-table-column prop="name" label="角色"></el-table-column>
					<el-table-column prop="description" label="描述"></el-table-column>
					<el-table-column prop="mobile" label="操作" width="">
						<template slot-scope="scope">
							<div class="zhanghao-flex">
								<span class="bianji" @click="do_row_edit(scope.row)">编辑</span>
								<span class="shanchu" v-if="scope.row.is_default!=1" @click="do_row_delete(scope.row)">删除</span>
							
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>



	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的
	import juese_add_modal from "@/components/account/juese_add_modal.vue"; //
	export default {
		components: {
			page,
			juese_add_modal,
		},
		data() {
			return {
				//提交数据
				data_list: [],
				forms: {
					keyword: '',
				},
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				list: [{
						juese: '角色',
					},
					{
						juese: '超级管理员',
					},
				],



				dialogVisible: false,
				activeIndex: 1, //默认选中
				guanli: [{
						title: '账号',
						path: '/Account_management',
						status: 0,
					},
					{
						title: '角色',
						path: '/Role_management',
						status: 1,
					},
				],
				form_select: {
					juese: '',
					zhuangtai: '',
					zhanghao: '',
				},
				jueseList: [{
					value: '选项1',
					label: '黄金糕'
				}, {
					value: '选项2',
					label: '双皮奶'
				}],
				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
			};
		},

		computed: {},
		watch: {},
		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
		},
		mounted() {

		},
		methods: {
			toStatus(item) {
				this.activeIndex = item.status
				this.$router.push({
					path: item.path
				})
			},

			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				this.$api("getAdminRoleList", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.data_list = res.data.list
					} else {
						alertErr(res.msg)
					}
				});
			},

			do_search() {
				this.pages.page = 1;
				this.get_list()
			},


			do_add() {
				this.$refs.juese_add_modal.init()
			},
			do_add_confirm() {
				this.pages.page = 1;
				this.get_list()
			},


			do_row_edit(row) {
				this.$refs.juese_add_modal.init(row)
			},
			do_row_delete(row) {
				this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api("delAdminRole", {
						id: row.id
					}, "post").then((res) => {
						alert(res)
						if (res.code == 200) {
							this.get_list()
						}
					});
				}).catch(() => {

				});
			},

		},

	};
</script>

<style scoped lang="less">
	// /deep/.el-checkbox {
	// 	display: block !important;
	// 	margin-top: 20px;
	// }

	/deep/.el-dialog {
		border-radius: 8px;
	}

	.content-info {
		padding: 0px 110px;

		.form-item {
			display: flex;
			align-items: center;
			margin-bottom: 14px;

			.values {
				flex: 1;
			}

			.chuangjian {
				width: 101px;
				height: 38px;
				background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
				border-radius: 4px 4px 4px 4px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 16px;
				margin-left: 14px;
				cursor: pointer;

				&:hover {
					opacity: 0.78;
				}
			}



			.select {
				flex: 1;
				display: flex;

				/deep/.el-select {
					flex: 1;
				}
			}
		}

		.title {
			font-size: 16px;
			display: flex;
			width: 85px;
			margin-right: 5px;

			&.requird::before {
				content: '*';
				display: block;
				color: red;
				margin-right: 5px;
			}
		}

	}


	.created-span {
		width: 80px;
		height: 31px;
		background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
		border-radius: 4px 4px 4px 4px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		color: #fff;
		cursor: pointer;

		&:hover {
			opacity: 0.85;
		}
	}

	.created-account {
		padding: 21px 21px 0 21px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.end-flex {
			display: flex;
			align-items: center;

			.item-1 {
				margin-left: 27px;

				display: flex;
				align-items: center;

				.key {
					margin-right: 10px;
					font-size: 14px;
					color: #424542;
					width: fit-content;
				}

				.form-value {
					flex: 1;
					overflow: hidden;
				}
			}
		}
	}

	.seacher-box {
		width: 80px;
		margin-left: 14px;
		height: 40px;
		background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
		border-radius: 0px 0px 0px 0px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 14px;
		cursor: pointer;

		&:hover {
			opacity: 0.85;

		}
	}

	.account-titlebox {
		.title {
			color: #333333;
			font-size: 14px;
			font-weight: bold;
			padding: 12px 17px;
			border-bottom: 1px solid #EDF0F6;
		}

		.two-toggle {
			padding: 0 21px;

			.tabs {
				display: flex;
				border-bottom: 1px solid #D9D9D9;

				span {
					width: 100px;
					color: #333333;
					font-size: 16px;
					display: flex;
					cursor: pointer;
					padding: 22px 0px 16px 0px;
					position: relative;
					justify-content: center;
					align-items: center;
					margin-right: 10px;

					&.active {
						color: #3697FD;

						&::after {
							content: '';
							position: absolute;
							width: 100%;
							height: 2px;
							background: #0564ca;
							bottom: 0;
							left: 0;
						}
					}
				}
			}
		}


	}


	/deep/.el-table__cell:nth-child(2) {
		padding-right: 50px;

		.cell {
			text-align: center;
		}
	}

	/deep/.el-table__cell:nth-child(1) {
		padding-left: 50px;
		padding-right: 50px;
	}

	.qiyong {
		width: 69px;
		height: 30px;
		background: #FFF0CA;
		border-radius: 2px 2px 2px 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #EC7217;
		font-size: 14px;
		margin: 0 auto;
	}

	.zhanghao-flex {
		display: flex;
		justify-content: center;
		align-items: center;

		span {
			font-size: 14px;
			color: #3697FD;
			margin: 0 27px 0 0;
			cursor: pointer;

			&:last-child {
				margin-right: 0;
			}
		}
	}

	// ---------------------------------






	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #ac8d8d;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>