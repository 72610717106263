import { render, staticRenderFns } from "./juese_add_modal.vue?vue&type=template&id=3604f815&scoped=true"
import script from "./juese_add_modal.vue?vue&type=script&lang=js"
export * from "./juese_add_modal.vue?vue&type=script&lang=js"
import style0 from "./juese_add_modal.vue?vue&type=style&index=0&id=3604f815&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3604f815",
  null
  
)

export default component.exports